import { createContext, ReactNode, useCallback, useContext, useState } from "react";
import { IoClose } from "react-icons/io5";

interface SetWarningData {
  warning: ReactNode
  type: 'warning' | 'error' | 'success'
  duration?: number
}

export interface WarningContextData {
  setWarning: ({ warning, type, duration }: SetWarningData) => void
  closeWarning: () => void;
}

interface WarningProviderData {
  children: ReactNode
}


const warningColor = {
  'warning': 'bg-orange-500',
  'error': 'bg-red-600',
  'success': 'bg-green-500'
}


export const WarningContext = createContext({} as WarningContextData)

export const WarningProvider = ({ children, ...args }: WarningProviderData) => {
  const [warningText, setWarningText] = useState<ReactNode>()
  const [warningType, setWarningType] = useState<'warning' | 'error' | 'success'>('error')
  const [, setCurrentTimeout] = useState<ReturnType<typeof setTimeout>>()

  const [isVisible, setIsVisible] = useState(false)

  const closeWarning = useCallback(() => {
    setIsVisible(false)
  }, [])

  const setWarning = useCallback(({ warning, type = 'error', duration = 5 }: SetWarningData) => {
    setCurrentTimeout(currentTimeout => {
      if (currentTimeout !== undefined)
        clearTimeout(currentTimeout)
      const newTimeout = setTimeout(() => {
        closeWarning()
      }, duration * 1000)

      return newTimeout
    }

    )
    setWarningText(warning)
    setWarningType(type)
    setIsVisible(true)

  }, [closeWarning])


  const Warning = () => {
    return (
      <div className={`absolute left-0 bottom-0 h-12 w-full ${warningColor[warningType]} text-white z-50 flex items-center justify-center ${(isVisible) ? 'animate-slide-in' : 'animate-slide-out'} ${warningText === undefined && 'opacity-0'}`}>
        {warningText}
        <button onClick={closeWarning} className="absolute right-5 h-8 w-8 p-0" ><IoClose className="h-full w-full" /></button>
      </div>
    )
  }

  return (
    <WarningContext.Provider value={{
      setWarning: setWarning,
      closeWarning: closeWarning
    }}>
      <div className="h-screen w-screen overflow-hidden relative">
        {children}
        <Warning />
      </div>
    </WarningContext.Provider>
  )
}

export const useWarnings = () => {
  return useContext(WarningContext)
}