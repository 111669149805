import { useAuth } from '../../../../contexts/UserContext'
import { useTranslation } from 'react-i18next'
import { IoAlarm, IoArrowBack, IoGameController, IoGift, IoHome, IoLockClosed, IoMedkit, IoPerson, IoSettings, IoShirt, IoTrash } from 'react-icons/io5'
import { Location, Navigate, Outlet, To, useLocation, useNavigate } from 'react-router-dom'
import { useWindowDimensions } from '../../../../utils/WindowDimensions'
import { Divider, VerticalDivider } from '../../../../components/Divider'
import { NavItem } from '../../../../components/NavItem'
import './Settings.sass'

export const Settings = () => {
  const location = useLocation()
  const { GetUser } = useAuth()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const user = GetUser()

  if ((location.pathname === '/dashboard/profile/settings' ||
    location.pathname === '/dashboard/profile/settings/') && width >= 768) {

    return (
      <Navigate to="/dashboard/profile/settings/personal" replace={true} />
    )
  }

  return (
    <div className="settings">
      {
        ((location.pathname === '/dashboard/profile/settings' || location.pathname === '/dashboard/profile/settings/') || width >= 768) && (
          <ul className="menu">
            <h1>{t('profile.cards.settings.settings')}</h1>

            <NavItem Icon={IoPerson} path="/dashboard/profile/settings/personal">
              {t('profile.cards.settings.personalSettings.personalSettings')}
            </NavItem>
            <Divider />
            <NavItem Icon={IoLockClosed} path="/dashboard/profile/settings/change_password">
              {t('profile.cards.settings.passwordChange.passwordChange')}
            </NavItem>
            <Divider />
            <NavItem Icon={IoSettings} path="/dashboard/profile/settings/language">
              {t('profile.cards.settings.platformSettings.language.language')}
            </NavItem>
            <Divider />
            <NavItem Icon={IoTrash} path="/dashboard/profile/settings/delete_account">
              {t('profile.cards.settings.deleteAccount.deleteAccount')}
            </NavItem>
            {
              user.type === "parent" && !(user.isPremium) && (
                <>
                  <Divider />
                  <NavItem Icon={IoGift} path="/dashboard/profile/settings/gift_code">
                    {t('profile.cards.settings.giftCode.giftCode')}
                  </NavItem>

                </>
              )

            }

          </ul>
        )
      }

      {width >= 768 && (
        <>
          <VerticalDivider />
          <Outlet />
        </>
      )}
    </div>
  )
}

export const GameUserSettings = () => {
  const location = useLocation()
  const { width } = useWindowDimensions()

  const regex = /\/dashboard\/game_user\/[0-9]+\/settings\/\D+/i
  const regexRootSettingsPath = /\/dashboard\/game_user\/[0-9]+\/settings(\/?)(?!\D+)/i

  if (regexRootSettingsPath.test(location.pathname) && width >= 768) {
    return (
      <Navigate to="profile" replace={true} />
    )
  }

  return (
    <div className="settings game">
      <div className="header">
        <ReturnButton />
      </div>
      <div className="main">
        {
          ((!(regex.test(location.pathname)) || width >= 768)) && (
            <GameUserSettingsItems />
          )
        }

        {width >= 768 && (
          <>
            <VerticalDivider />
            <div className="outlet">
              <Outlet />
            </div>

          </>
        )}
      </div>
    </div>
  )
}

const GameUserSettingsItems = () => {
  const { t } = useTranslation()
  const { user } = useAuth()



  return (
    <ul className="menu">
      <h1>{t('profile.cards.settings.settings')}</h1>

      <h2>{t("gameUserProfile.settings.profileSettings.title")}</h2>
      <Divider />

      <NavItem Icon={IoPerson} path="profile">
        {t('gameUserProfile.settings.profileSettings.profile.title')}
      </NavItem>
      {
        user.type !== "professional" ? (
          <>
            <NavItem Icon={IoMedkit} path="linked_professional">
              {t('gameUserProfile.settings.profileSettings.linkedProfessional.title')}
            </NavItem>

            <NavItem Icon={IoTrash} path="delete_profile">
              {t('gameUserProfile.settings.profileSettings.delete_profile.title')}
            </NavItem>
          </>
        ) : (
          <NavItem Icon={IoTrash} path="unlink_profile">
            {t('gameUserProfile.settings.profileSettings.unlinkGameUser.title')}
          </NavItem>
        )
      }

      <span className="h-4" />

      <h2>{t("gameUserProfile.settings.gameSettings.title")}</h2>
      <Divider />

      <NavItem Icon={IoHome} path="game_settings/game_areas">
        {t('gameUserProfile.settings.gameSettings.gameAreas')}
      </NavItem>

      <NavItem Icon={IoGameController} path="game_settings/game_activities">
        {t('gameUserProfile.settings.gameSettings.gameActivities')}
      </NavItem>

      <NavItem Icon={IoShirt} path="game_settings/game_clothes">
        {t("gameUserProfile.settings.gameClothes.title")}
      </NavItem>

      <NavItem Icon={IoAlarm} path="game_settings/game_time">
        {t('gameUserProfile.settings.gameTime.title')}
      </NavItem>
    </ul>
  )
}


function ReturnButton() {
  const location = useLocation()
  const state = location.state as { from: Location }
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  let path: To = ""

  const regexPage = /\/dashboard\/game_user\/[0-9]+\/settings\/\D+/i
  const regexUser = /\/dashboard\/game_user\/[0-9]+/i

  const matchUserPath = location.pathname.match(regexUser) || [""]
  if (width < 768) {
    if (regexPage.test(location.pathname)) {
      path = matchUserPath[0] + "/settings"
    } else {
      path = matchUserPath[0]

    }
  } else {
    path = state ? state.from.pathname : matchUserPath[0]
  }

  return (
    <button type="button" onClick={e => { e.stopPropagation(); navigate(path) }}>
      <IoArrowBack />
    </button>
  )
}