import { useTranslation } from 'react-i18next'
import { BiTargetLock } from 'react-icons/bi'
import { HiClock } from 'react-icons/hi'
import { IoClose, IoStopwatch } from 'react-icons/io5'
import { AnalyticCard } from '../../components/AnalyticCard'
// import { BarChart } from '../../components/Chart'
import { useMemo } from 'react'
import { LineBarChart } from '../../components/Chart/LineBarChart'
import { GameUserData } from '../../contexts/UserContext'
import { GameSessionChartData, LineChartData } from '../../utils/ChartFunctions'

export const DashboardFrame = ({ userData }: { userData: Omit<GameUserData, "max_game_time"> }) => {
  const { t } = useTranslation()

  return userData ? (

    <DashboardData userData={userData} />

  ) : (

    <div className="flex items-center flex-col w-full h-full py-20 lg:pb-0">
      <p>{t('dashboard.errors.noData')}</p>
    </div>

  )
}

const DashboardData = ({ userData, ...args }: { userData: Omit<GameUserData, "max_game_time"> }) => {
  const { t } = useTranslation()

  const gameSessionDurationData = GameSessionChartData({ data: userData['game_session_duration'], t })
  // const activitySessionDurationData = useMemo(() => LineChartData({ data: userData['activity_session_duration'], t }), [userData, t])
  const userErrorsData = useMemo(() => LineChartData({ data: userData['user_errors'], t }), [userData, t])
  const userPrecisionData = useMemo(() => LineChartData({ data: userData['user_precision'], t }), [userData, t])
  const userResponseData = useMemo(() => LineChartData({ data: userData['user_response'], t }), [userData, t])

  return (
    <div className="flex flex-col justify-between items-start max-w-full h-full gap-4 py-4" data-testid="chart-dashboard">
      <div className="flex flex-col lg:flex-row w-full min-h-1/5 items-center justify-evenly gap-4 flex-warp p-4">
        <div className="flex flex-col lg:flex-row flex-nowrap w-full h-full gap-4">
          <AnalyticCard
            Icon={HiClock}
            title={t('dashboard.cards.session.title')}
          >
            <>
              {
                gameSessionDurationData.averageValueObj.days > 0 && (
                  <p>
                    {`${gameSessionDurationData.averageValueObj.days} ${t('dashboard.cards.session.time.days')}`}
                    {(gameSessionDurationData.averageValueObj.hours > 0 && gameSessionDurationData.averageValueObj.minutes > 0) && ','}
                  </p>
                )
              }


              {
                gameSessionDurationData.averageValueObj.hours > 0 && (
                  <p>
                    {(gameSessionDurationData.averageValueObj.days > 0 && gameSessionDurationData.averageValueObj.minutes <= 0) && `${t('dashboard.cards.and')} `}
                    {`${gameSessionDurationData.averageValueObj.hours} ${t('dashboard.cards.session.time.hours')}`}
                    {(gameSessionDurationData.averageValueObj.minutes > 0 && gameSessionDurationData.averageValueObj.seconds > 0) && ','}
                  </p>
                )
              }


              {
                gameSessionDurationData.averageValueObj.minutes > 0 && (
                  <p>
                    {(gameSessionDurationData.averageValueObj.hours > 0 && gameSessionDurationData.averageValueObj.seconds <= 0) && `${t('dashboard.cards.and')} `}
                    {`${gameSessionDurationData.averageValueObj.minutes} ${t('dashboard.cards.session.time.minutes')}`}
                  </p>
                )
              }


              {
                gameSessionDurationData.averageValueObj.seconds > 0 && (
                  <p>
                    {(gameSessionDurationData.averageValueObj.minutes > 0) && `${t('dashboard.cards.and')} `}
                    {`${gameSessionDurationData.averageValueObj.seconds} ${t('dashboard.cards.session.time.seconds')}`}
                  </p>
                )
              }

              {
                gameSessionDurationData.totalValue === 0 && (
                  <p> 0 {t('dashboard.cards.session.time.seconds')}</p>
                )
              }
            </>

          </AnalyticCard>
          <AnalyticCard
            Icon={IoStopwatch}
            title={t('dashboard.cards.response.title')}
          >
            <>
              {
                userResponseData.amount > 0 ?
                  (userResponseData.totalValue) / (userResponseData.amount) > 2 ? `${((userResponseData.totalValue) / (userResponseData.amount)).toFixed(2)} ${t('dashboard.cards.response.time.seconds')}` : `${Math.floor((userResponseData.totalValue) / (userResponseData.amount) * 1000)} ${t('dashboard.cards.response.time.milliseconds')}`
                  :
                  `0 ${t('dashboard.cards.response.time.seconds')}`
              }
            </>
          </AnalyticCard>
        </div>
        <div className="flex flex-col lg:flex-row flex-nowrap w-full h-full gap-4">

          <AnalyticCard
            Icon={IoClose}
            title={t('dashboard.cards.errors.title')}
          >
            <>
              {
                userErrorsData.amount > 0 ?
                  `${((userErrorsData.totalValue) / (userErrorsData.amount)).toFixed(2)} ${t('dashboard.cards.errors.unit')}`
                  :
                  `0 ${t('dashboard.cards.errors.unit')}`
              }
            </>
          </AnalyticCard>
          <AnalyticCard
            Icon={BiTargetLock}
            title={t('dashboard.cards.precision.title')}
          >
            <>
              {
                userPrecisionData.amount > 0 ?
                  `${((userPrecisionData.totalValue / userPrecisionData.amount) * 100).toFixed(2)}%`
                  :
                  '0%'
              }
            </>
          </AnalyticCard>
        </div>
      </div>

      <div className="flex h-full justify-evenly items-start w-full flex-wrap gap-2">
        <div className="flex flex-col lg:flex-row flex-nowrap w-full lg:h-1/2 p-4 flex-shrink gap-4">
          <LineBarChart title={t('dashboard.charts.gameTime.title')} data={userData['game_session_duration']} />
          <LineBarChart title={t('dashboard.charts.activityTime.title')} data={userData['activity_session_duration']} />
        </div>
        <div className="flex flex-col lg:flex-row flex-nowrap w-full lg:h-1/2 p-4 flex-shrink gap-4">
          <LineBarChart title={t('dashboard.charts.activityErrors.title')} data={userData['user_errors']} />
          <LineBarChart title={t('dashboard.charts.responseTime.title')} data={userData['user_response']} />
        </div>
      </div>
    </div>
  )
}